// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "bootstrap"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("trix")
require("@rails/actiontext")

import { CountUp } from 'countup.js';


Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.delde = window.delde || {};

window.delde.countersRun = false;

window.delde.runCounters = function() {
  if (!window.delde.countersRun) {
    var items = ['jackets', 'pants', 'shirts'];
    var opts = {};

    for (var i of items) {
      var el = document.getElementById(i + '-counter');
      new CountUp(i + '-counter', el.dataset.counter, opts).start();
    }
    window.delde.countersRun = true;
  }
}


document.addEventListener("turbolinks:load", function() {

  var stats = document.getElementById("stats-counter");
  if (stats) {
    var observer = new IntersectionObserver(function(el) {
      if(el[0].isIntersecting === true) {
        window.delde.runCounters();
      }

    }, { threshold: 1 });

    var el = document.getElementById('jackets-counter');
    observer.observe(el);

  }

});
